<template>
  <div>
    <b-overlay :show="isShowLoader" no-wrap style="z-index: 20000"></b-overlay>
    <b-container fluid class="mainContainer">
      <b-row style="height: 100vh">
        <b-col col lg="2" class="leftPanelStyle"
          ><b-row class="ownerName">
            <b-col col lg="3">
              <b-img
              :src="$store.state.login_account.avatar"
                rounded="circle"
                class="profileImage"
              ></b-img>
            </b-col>
            <b-col col lg="9" style="padding:right: 0px;">
              <p class="profileNameList">
                {{ $store.state.login_account.name }}
              </p>
            </b-col>
          </b-row>
          <!-- ---------------------------------- System message ---------------------------------- -->
          <b-row
            style="padding: 6px 0px; height: 55px; cursor: pointer"
            @click="onRecentUser('system')"
          >
            <b-col col lg="3" style="padding-right: 0px">
              <b-avatar
                :src="require('@/assets/img/annouce.jpeg')"
              ></b-avatar>
            </b-col>

            <b-col col lg="6" style="padding-right: 0px">
              <p class="profileName" style="padding-top: 10px">系统消息</p>
            </b-col>
            <b-col col lg="3">
              <div class="messageCount">
                <b-badge
                  variant="danger"
                  style="border-radius: 25px"
                  v-if="$store.state.system_unread"
                  >{{ $store.state.system_unread }}</b-badge
                >
              </div>
            </b-col>
          </b-row>
          <!------------------------------------ Agent list with message count ---------------------------------- -->

          <div v-if="agentListPannel" class="container1">
            <b-row
              v-for="i in $store.state.message_list"
              :key="i.id"
              style="padding: 6px 0px; cursor: pointer"
              @click="onRecentUser(i)"
            >
              <b-col collg="3" style="padding-right: 0px">
                <!-- <b-avatar
                  badge
                  badge-top
                  badge-variant="success"
                  v-bind:src="i.user.avatar"
                ></b-avatar> -->
                <b-avatar v-bind:src="i.user.avatar"></b-avatar>
              </b-col>

              <b-col col lg="6">
                <p class="profileName" style="padding-top: 10px">
                  {{
                    i.user.username === "" ? i.user.account : i.user.username
                  }}
                </p>
              </b-col>
              <b-col col lg="3">
                <div class="messageCount">
                  <b-badge
                    variant="danger"
                    style="border-radius: 25px"
                    v-if="i.unread > 0"
                    >{{ i.unread }}</b-badge
                  >
                </div>
              </b-col>
            </b-row>
            <!------------------------------------ Bottom tabs (Group and Info) ---------------------------------- -->
          </div>
          <!------------------------------------ Channel list with message count ---------------------------------- -->

          <div v-if="channelListPannel" class="container1">
            <b-row v-if="$store.state.channel_list.length === 0"
              ><p>--- No channels ---</p></b-row
            >
            <b-row
              v-else
              v-for="i in $store.state.channel_list"
              :key="i.id"
              @click="onRecentChannel(i)"
              style="padding: 6px 0px; cursor: pointer"
            >
              <b-col col lg="3" style="padding-right: 0px">
                <b-avatar v-bind:src="i.user.avatar"></b-avatar>
              </b-col>

              <b-col col lg="6">
                <p class="profileName" style="padding-top: 10px">
                  {{ i.user.name }}
                </p>
              </b-col>
              <b-col col lg="3">
                <div class="messageCount">
                  <b-badge
                    variant="danger"
                    style="border-radius: 25px"
                    v-if="i.unread > 0"
                    >{{ i.unread }}</b-badge
                  >
                </div>
              </b-col>
            </b-row>
            <!------------------------------------ Bottom tabs (Group and Info) ---------------------------------- -->
          </div>
          <b-row class="bottomBar">
            <!-- ---------------------------------- Search User and channel ---------------------------------- -->
            <b-col col class="bottomContent" v-show="agentListPannel">
              <p
                class="groupName"
                v-b-toggle.sidebar-footer_search
                @click="onSearchButton"
              >
                <b-icon icon="search" aria-hidden="true"></b-icon>
              </p>
            </b-col>
            <b-col col class="bottomContent" v-show="channelListPannel">
              <p
                class="groupName"
                v-b-toggle.sidebar-footer_searchChannel
                @click="onSearchButtonChannel"
              >
                <b-icon icon="search" aria-hidden="true"></b-icon>
              </p>
            </b-col>
            <!-- ---------------------------------- App info ----------------------------------
            <b-col col class="bottomContent">
              <p
                class="groupName"
                @click="$bvModal.show('modal-scoped_appInfo')"
              >
                <b-icon icon="info-circle" aria-hidden="true"></b-icon>
              </p>
            </b-col> -->
            <!-- ---------------------------------- Agents ---------------------------------- -->
            <b-col col class="bottomContent">
              <p class="groupName" @click="showAgentList()">
                <b-icon icon="person" aria-hidden="true"></b-icon>
              </p>
            </b-col>
            <!-- ---------------------------------- Group ---------------------------------- -->
            <b-col col class="bottomContent">
              <p class="groupName" v-b-toggle.sidebar-footer_group>
                <b-icon icon="chat-text" aria-hidden="true"></b-icon>
              </p>
            </b-col>

            <!-- ---------------------------------- Channel ---------------------------------- -->
            <!-- <b-col col>
              <p class="groupName" @click="showChannelList()">
                <b-icon icon="people" aria-hidden="true"></b-icon>
              </p>
            </b-col> -->

            <!-- ======= Group Section - Componet name "Groups" ======-->
            <groupsList></groupsList>
            <!-- ======= Search user sidebar Section - Componet name "Searchuser" ====== -->
            <searchUser></searchUser>
            <!-- ======= Search user sidebar Section - Componet name "Searchuser" ====== -->
            <channelSearch></channelSearch>
            <!-- ============== App Info Modal - Componet name "AppInfo"================ -->
            <appInfo></appInfo>
          </b-row>
        </b-col>

        <!-- =========================================== Right pannel ================================================ -->
        <b-col col lg="10" style="background: #efefef; height: 100%">
          <b-row style="background: #435f7a; height: 50px">
            <b-col col lg="6">
              <p class="chatUsername">
                {{
                  selectedUser.username === ""
                    ? selectedUser.account
                    : selectedUser.username
                }}
              </p>
              <!-- <p v-if="channelButtonGroup" class="chatUsername">
                {{ selectedChannel.channelname }}
              </p> -->
            </b-col>
            <!-- ======================================================================================================== -->
            <!-- -------------------------------------- Agent button group--------------------------------------------- -->
            <!-- ======================================================================================================== -->
            <b-col col lg="6" v-if="agentButtonGroup" style="padding-top: 5px">
              <b-button-toolbar style="float: right">
                <b-button-group class="mr-1">
                  <!-- ---------------------------------- Transfer agent icon ---------------------------------- -->
                  <b-button
                    v-b-modal.modal-scrollable_trans
                    style="background: #2c3e50"
                    v-if="selectedUser.id != null"
                  >
                    <b-icon icon="person-check" aria-hidden="true"></b-icon>
                  </b-button>
                  <!-- ====== Transfer agent Modal  - Componet name "AgentTransfer"======= -->
                  <agentTransfer
                    v-bind:selectedUser="selectedUser"
                  ></agentTransfer>
                  <!-- ---------------------------------- Agent Details icon ---------------------------------- -->
                  <b-button
                    v-b-modal.modal-scoped_agentdetail
                    style="background: #2c3e50"
                    v-if="selectedUser.id != null"
                  >
                    <b-icon
                      icon="info-circle"
                      aria-hidden="true"
                      style="line-height: 50px; color: #ffffff"
                    ></b-icon>
                  </b-button>
                  <!-- ========= Agent Details Modal - Componet name "AgentsInfo" ======== -->
                  <agentsInfo v-bind:selectedUser="selectedUser"></agentsInfo>
                  <!-- ----------------------------------Question list icon ---------------------------------- -->

                  <b-button
                    v-b-modal.modal-scrollable_question
                    style="background: #2c3e50"
                    v-if="selectedUser.id != null"
                  >
                    <b-icon icon="question-circle" aria-hidden="true"></b-icon>
                  </b-button>
                  <!-- ====== Question List Modal - Componet name "QuestionList" ========= -->
                  <questionsList v-bind:parentContext="this"> </questionsList>
                  <!-- ---------------------------------- History icon ---------------------------------- -->
                  <b-button
                    v-b-modal.modal-scrollable_history
                    style="background: #2c3e50"
                    v-if="selectedUser.id != null"
                    @click="getHistoryMsg"
                  >
                    <b-icon
                      icon="clock-history"
                      aria-hidden="true"
                      style="line-height: 50px; color: #ffffff"
                    ></b-icon>
                  </b-button>
                  <!-- ======= History message Modal - Componet name "History" ======= -->
                  <history
                    ref="getHistoryMsg"
                    v-bind:selectedUser="selectedUser"
                  ></history>

                  <!-- ---------------------------------- Logout icon ---------------------------------- -->
                  <b-button style="background: #2c3e50" @click="logout">
                    <b-icon icon="box-arrow-right" aria-hidden="true"></b-icon>
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
              <!-- <b-alert v-model="emptyMessageError" variant="danger" dismissible>
                No message to send!
              </b-alert> -->
            </b-col>
            <!-- ======================================================================================================== -->
            <!-- -------------------------------------- Channel button group--------------------------------------------- -->
            <!-- ======================================================================================================== -->
            <b-col col lg="6" style="padding-top: 5px">
              <b-button-toolbar style="float: right">
                <b-button-group v-if="channelButtonGroup" class="mr-1">
                  <!-- ---------------------------------- Channel member list and permission ---------------------------------- -->
                  <b-button
                    v-b-modal.modal-scoped_channelUserPermission
                    @click="showChannelMembers()"
                    style="background: #2c3e50"
                    v-if="selectedUser.id != null"
                  >
                    <b-icon icon="list-ul" aria-hidden="true"></b-icon>
                  </b-button>
                  <!-- ====== Channel member list and permission Modal  - Componet name ""======= -->
                  <channelPermission
                    ref="showChannelMembers"
                    v-bind:selectedUser="selectedUser"
                  ></channelPermission>
                  <!-- ---------------------------------- Channel add & remove user---------------------------------- -->
                  <b-button
                    v-b-modal.modal-scoped_channelAddRemove
                    @click="showMembersList()"
                    style="background: #2c3e50"
                    v-if="selectedUser.id != null"
                  >
                    <b-icon
                      icon="pencil-square"
                      aria-hidden="true"
                      style="line-height: 50px; color: #ffffff"
                    ></b-icon>
                  </b-button>
                  <!-- ========= Channel add & remove user Modal - Componet name "" ======== -->
                  <channelAddRemoveUser
                    ref="showMembersList"
                    v-bind:selectedUser="selectedUser"
                  ></channelAddRemoveUser>

                  <!-- ----------------------------------Channel search and add user ---------------------------------- -->
                  <b-button
                    v-b-modal.modal-scoped_channelAddSearch
                    style="background: #2c3e50"
                    v-if="selectedUser.id != null"
                  >
                    <b-icon icon="person-plus" aria-hidden="true"></b-icon>
                  </b-button>
                  <!-- ====== Channel search and add useruestion List Modal - Componet name "" ========= -->
                  <channelSearchAddUser v-bind:selectedUser="selectedUser">
                  </channelSearchAddUser>
                  <!-- ----------------------------------Channel History ---------------------------------- -->
                  <b-button
                    v-b-modal.modal-scrollable_historyChannel
                    @click="getHistoryChannel"
                    style="background: #2c3e50"
                    v-if="selectedUser.id != null"
                  >
                    <b-icon
                      icon="clock-history"
                      aria-hidden="true"
                      style="line-height: 50px; color: #ffffff"
                    ></b-icon>
                  </b-button>
                  <!-- ======= Channel History Modal - Componet name "" ======= -->
                  <channelHistory
                    ref="getHistoryChannel"
                    v-bind:selectedUser="selectedUser"
                  ></channelHistory>

                  <!-- ---------------------------------- Logout icon ---------------------------------- -->
                  <b-button style="background: #2c3e50" @click="logout">
                    <b-icon icon="box-arrow-right" aria-hidden="true"></b-icon>
                  </b-button>
                </b-button-group>
              </b-button-toolbar>
              <!-- <b-alert v-model="emptyMessageError" variant="danger" dismissible>
                No message to send!
              </b-alert> -->
            </b-col>
          </b-row>
          <b-row class="chatWindowBody">
            <ul id="chatWindowBodyUl">
              <!-- ---------------------------------- Recieve message UI ------------------------------------>
              <li v-for="(message, id) in selectedMessageList" :key="id">
                <div
                  v-bind:class="[
                    message.sender == selectedUser.id ? 'receive' : 'sent',
                  ]"
                >
                  <b-icon
                    icon="arrow-clockwise"
                    class="rounded-circle bg-danger"
                    variant="light"
                    font-scale="2"
                    v-if="message.sendfail"
                    @click="resendFailMessage(message)"
                    style="cursor: pointer"
                  ></b-icon>
                  <b-icon
                    v-if="message.sending"
                    icon="clock"
                    class="rounded-circle"
                    font-scale="1"
                  ></b-icon>
                  <b-icon
                    v-if="
                      !message.sending &&
                      message.sender != selectedUser.id &&
                      !message.sendfail
                    "
                    icon="check"
                    class="rounded-circle"
                    font-scale="1"
                  ></b-icon>
                  <img
                    :src="require('@/assets/img/logo.png')"
                    class="imageleft"
                    v-bind:class="[
                      message.sender === selectedUser.id
                        ? 'imageleft'
                        : 'imageright',
                    ]"
                  />
                  <div class="talktext">
                    <p
                      v-if="message.format == 0"
                      v-html="processContent(message.content)"
                      class="textStyle"
                    ></p>
                    <b-img
                      v-if="message.format == 1"
                      :src="
                        $baseStoragePath + JSON.parse(message.content).image
                      "
                      @click="
                        showImg(
                          $baseStoragePath + JSON.parse(message.content).image
                        )
                      "
                      style="width: auto; height: 200px; max-width: 600px"
                    ></b-img>

                     <!-- ================================= voice record messages ========================= -->
                    <audio
                      controls
                      v-if="message.format == 2"
                      :src="$baseStoragePath + JSON.parse(message.content).file"
                      type="audio/mpeg"
                    ></audio>
                   

                    <b-embed
                      v-if="message.format == 8"
                      type="video"
                      aspect="4by3"
                      controls
                    >
                      <source
                        :src="
                          $baseStoragePath + JSON.parse(message.content).video
                        "
                        type="video/mp4"
                      />
                    </b-embed>
                    <!-- ================================= File ========================= -->
                    <!-- def.png == doc.png == gif.png == html.png == jpeg.png == mp3.png == png.png == ppt.png == rar.png == txt.png  == xls.png == zip.png -->
                    <div
                      v-if="message.format == 3"
                      @click="onFile(message.content)"
                      class="receiveFileStyle"
                    >
                      <img
                        v-bind:src="getFileType(message.content)"
                        style="width: auto; height: 100px"
                      />
                      <p
                        class="fileName"
                        style="color: #ced4da; font-size: 13px"
                      >
                        {{ JSON.parse(message.content).name }}
                      </p>
                    </div>
                    <p
                      v-if="channelButtonGroup && message.action == 3"
                      style="text-align: right; color: #ced4da; font-size: 13px"
                    >
                      {{ getMessageSenderInformation(message.extra) }}
                    </p>
                    <p
                      style="text-align: right; color: #ced4da; font-size: 13px"
                    >
                      {{ getTime(message.timestamp) }}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <div id="bottomChat"></div>
          </b-row>

          <!-- ---------------------------------- message input ---------------------------------- -->
          <b-row class="message_input" v-if="selectedUser.id">
            <b-input-group size="sm" style="padding: 0px">
              <!-- ======== emoji icon ========== -->
              <b-input-group-prepend id="showPopoverEmoji">
                <b-input-group-text style="font-size: 20px; cursor: pointer">
                  <b-icon icon="emoji-smile"></b-icon>
                </b-input-group-text>
              </b-input-group-prepend>
              <!-- ========= emoji popup ========= -->
              <b-popover
                target="showPopoverEmoji"
                placement="topright"
                :show.sync="emojiPopupShow"
              >
                <ul class="emojiDesign">
                  <li
                    class="emojiListDesign"
                    v-for="(item, index) in emojiIcons"
                    :key="index"
                    @click="onEmoji(item)"
                  >
                    <b-img :src="item.text" />
                  </li>
                </ul>
              </b-popover>
              <!-- ----------------------------------input area ---------------------------------- -->
              <!-- <b-form-input type="text"></b-form-input> -->
              <b-form-textarea
                v-model="inputMessage"
                id="textarea-small"
                size="sm"
                v-on:keyup.enter.exact="sendMessage(0)"
                v-on:keyup.shift.enter="inputMessage + '\r\n'"
                @paste="onPaste"
              ></b-form-textarea>
              <!-- =======file attachment icon ======= -->
              <b-input-group-append>
                <b-input-group-text
                  id="showAttachments"
                  style="font-size: 20px; cursor: pointer"
                  ><b-icon icon="paperclip"></b-icon>
                </b-input-group-text>
                <!-- ========Attachment popup ======= -->
                <b-popover
                  target="showAttachments"
                  triggers="click"
                  placement="topleft"
                  :show.sync="popoverShow"
                >
                  <b-button-toolbar style="padding: 10px">
                    <b-button-group>
                      <b-button title="Upload Image" v-model="file1">
                        <input
                          id="fileUploadImage"
                          ref="fileUploadImage"
                          type="file"
                          hidden
                          accept="image/*"
                          @change="onImageUpload"
                        />
                        <b-icon
                          icon="card-image"
                          accept="image/*"
                          @click="chooseImage()"
                          aria-hidden="true"
                        ></b-icon>
                      </b-button>
                      <input
                        id="fileUploadFile"
                        type="file"
                        accept="file/*"
                        hidden
                        @change="onFileUpload"
                      />
                      <b-button title="Uploag file">
                        <b-icon
                          icon="folder"
                          aria-hidden="true"
                          @click="chooseFile()"
                        ></b-icon>
                      </b-button>
                      <input
                        id="fileUploadVideo"
                        type="file"
                        accept="video/*"
                        hidden
                        @change="onVideoUpload"
                      />
                      <b-button title="Upload video">
                        <b-icon
                          icon="camera-video"
                          aria-hidden="true"
                          @click="chooseVideo()"
                        ></b-icon>
                      </b-button>
                    </b-button-group>
                  </b-button-toolbar>
                </b-popover>
                <!-- =========send message icon ========== -->
                <b-input-group-text
                  @click="sendMessage(0)"
                  style="font-size: 20px; cursor: pointer"
                >
                  <b-icon icon="chevron-right"></b-icon>
                </b-input-group-text>
              </b-input-group-append>
            </b-input-group>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
// import "viewerjs/dist/viewer.css";
// import Viewer from "v-viewer";
import Vue from "vue";
import moment from "moment";
import CimSdk from "../assets/cim/cim.web.sdk";
import "../assets/cim/message";
import "../assets/cim/replybody";
import "../assets/cim/sentbody";
import $ from "jquery";
import QuestionList from "./QuestionList";
import Groups from "./Groups";
import History from "./History";
import AgentTransfer from "./AgentTransfer";
import AgentsInfo from "./AgentsInfo.vue";
import SearchUser from "./SearchUser";
import ChannelSearch from "./ChannelSearch";
import AppInfo from "./AppInfo";
import hevueImgPreview from "hevue-img-preview";
Vue.use(hevueImgPreview);
import eventBus from "../assets/js/eventBus";
import ChannelPermission from "./ChannelPermission";
import ChannelAddRemoveUser from "./ChannelAddRemoveUser";
import ChannelSearchAddUser from "./ChannelSearchAddUser";
import ChannelHistory from "./ChannelHistory";
import noticeSound from "../assets/sound/notice.mp3";
// Vue.use(Viewer);
export default {
  name: "ChatWindow",
  components: {
    questionsList: QuestionList,
    groupsList: Groups,
    history: History,
    agentTransfer: AgentTransfer,
    searchUser: SearchUser,
    channelSearch: ChannelSearch,
    agentsInfo: AgentsInfo,
    appInfo: AppInfo,
    channelPermission: ChannelPermission,
    channelAddRemoveUser: ChannelAddRemoveUser,
    channelSearchAddUser: ChannelSearchAddUser,
    channelHistory: ChannelHistory,
  },
  data() {
    return {
      showPopoverEmoji: false,
      file1: null,
      emojiIcons: [
        {
          text: this.$baseURL + "/resource/layim/images/face/0.png",
          key: "[微笑]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/1.png",
          key: "[爱你]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/2.png",
          key: "[拜拜]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/3.png",
          key: "[悲伤]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/4.png",
          key: "[鄙视]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/5.png",
          key: "[闭嘴]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/6.png",
          key: "[馋嘴]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/7.png",
          key: "[吃惊]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/8.png",
          key: "[鼓掌]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/9.png",
          key: "[哈哈]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/10.png",
          key: "[害羞]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/11.png",
          key: "[黑线]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/12.png",
          key: "[可爱]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/13.png",
          key: "[可怜]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/14.png",
          key: "[亲亲]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/15.png",
          key: "[生病]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/16.png",
          key: "[偷笑]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/17.png",
          key: "[挖鼻]",
        },
        {
          text: this.$baseURL + "/resource/layim/images/face/18.png",
          key: "[委屈]",
        },
      ],
      sendImage: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQx1e3Brs1-mXteyLcxqZxVXQQhMQLKUcnLxA&usqp=CAU",
      ],
      selectedUser: { username: "" },
      selectedMessageList: [],
      inputMessage: "",
      emptyMessageError: false,
      isShowLoader: false,
      popoverShow: false,
      emojiPopupShow: false,
      agentListPannel: true,
      channelListPannel: false,
      agentButtonGroup: true,
      channelButtonGroup: false,
      //======== Channel
      // allChannelList: "",
      // selectedChannel: { channelname: "", channelid: "" },
    };
  },
  computed: {},
  methods: {
    chooseImage: function () {
      document.getElementById("fileUploadImage").click();
      this.onClose();
    },
    chooseVideo: function () {
      document.getElementById("fileUploadVideo").click();
      this.onClose();
    },
    chooseFile: function () {
      document.getElementById("fileUploadFile").click();
      this.onClose();
    },

    showImg(url) {
      this.$hevueImgPreview(url);
    },

    onCloseEmojiPop() {
      this.emojiPopupShow = false;
    },

    onClose() {
      this.popoverShow = false;
    },

    /**
     * check if the there is login user. if not move to login
     */
    isLogin() {
      if (!this.$store.state.login_account) {
        this.$router.push({ name: "Login" });
      } else {
        this.startChatSystem();
        this.loadOnlineUsers();
      }
    },
    /**
     * logout current user without clear cache
     */
    logout() {
      // localStorage.clear();
      this.$store.commit("clearToken");
      this.$router.push({ name: "Login" });
      window.location.reload();
    },
    /**
     * start the chat socket
     */
    startChatSystem() {
      CimSdk.connect(this);
    },
    /**
     * no implementaion yet
     */
    onReplyReceived(reply) {
      localStorage.setItem("lastHeartBeat", reply.timestamp);
      if (reply.key === "client_bind") {
        if (reply.code === "200") {
          CimSdk.startHeartBeat();
        }
      } else if (reply.key === "heartbeat") {
        //here comes heartbeat
      }
    },
    /**
     * this method gets invoked when new message comes from socket
     * and this will pass that information to the store
     */
    onMessageReceived(message) {
      // console.log(message);
      this.$store.commit("setMessage", {
        message: message,
        caller: this,
        sentMessage: false,
      });
      this.receiveRequest(message.id);
      //if chat already open, set the read status
      if (
        this.selectedUser.account === message.sender &&
        this.agentButtonGroup
      ) {
        this.$store.commit("readAllUnread", {
          account: this.selectedUser.account,
          context: this,
        });
      }

      if (this.selectedUser.id == message.sender && this.channelButtonGroup) {
        this.$store.commit("readAllUnreadChannel", {
          account: this.selectedUser.id,
          context: this,
        });
      }
      // setTimeout(() => {
      $(".chatWindowBody").animate(
        { scrollTop: $("#chatWindowBodyUl").prop("scrollHeight") },
        500
      );
      // }, 500);
    },
    /**
     * method to handle click on user from the left menu
     * set selected user
     * populate selected users' messages
     * set unread messages as read
     */
    onRecentUser(item) {
      //show agent button group in the top
      this.agentButtonGroup = true;
      this.channelButtonGroup = false;

      if (item === "system") {
        //load system messages
        this.selectedUser = { id: null, username: "系统消息" };
        this.selectedMessageList = this.$store.state.system_messages;
        this.loadSystemMessages();
      } else {
        this.selectedUser = item.user;
        this.selectedMessageList = item.messages;

        //set unread count to 0;
        item.unread = 0;
        //set unread messages read
        this.$store.commit("readAllUnread", {
          account: this.selectedUser.account,
          context: this,
        });
        this.$store.commit("updateMessageItem", item);
      }
      setTimeout(() => {
        $(".chatWindowBody").animate(
          { scrollTop: $("#chatWindowBodyUl").prop("scrollHeight") },
          500
        );
      }, 500);
    },
    /**
     * api to mark messages as read
     */
    readRequest(id, account) {
      let that = this;
      that
        .$http({
          url: "api/message/read/" + id + "/" + account,
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          let data = res.data;
          // if (data.code == 200) {
          //   that.$store.commit("updateUser", data.data);
          // }
        });
    },
    receiveRequest(id) {
      let that = this;
      that
        .$http({
          url: "/api/message/received/" + id,
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {});
    },
    /**
     * convert timestamp to human readable date time
     */
    getTime(timestamp) {
      return moment(timestamp).format("MMMM Do YYYY, h:mm:ss a");
    },
    getFileType(content) {
      let fileNameArray = JSON.parse(content).name.split(".");
      let fileType = fileNameArray[fileNameArray.length - 1];
      switch (fileType) {
        case "pdf":
          return require("../assets/img/chat_files/pdf.png");
        case "doc":
          return require("../assets/img/chat_files/doc.png");
        case "docx":
          return require("../assets/img/chat_files/doc.png");
        case "ppt":
          return require("../assets/img/chat_files/ppt.png");
        case "xls":
          return require("../assets/img/chat_files/xls.png");
        case "xlsx":
          return require("../assets/img/chat_files/xls.png");
        case "jpeg":
          return require("../assets/img/chat_files/jpeg.png");
        case "jpg":
          return require("../assets/img/chat_files/jpeg.png");
        case "mp3":
          return require("../assets/img/chat_files/mp3.png");
        case "png":
          return require("../assets/img/chat_files/png.png");
        case "rar":
          return require("../assets/img/chat_files/rar.png");
        case "txt":
          return require("../assets/img/chat_files/txt.png");
        case "zip":
          return require("../assets/img/chat_files/zip.png");
        case "gif":
          return require("../assets/img/chat_files/gif.png");
        default:
          return require("../assets/img/chat_files/def.png");
      }
    },

    /**
     * get user information on given account
     * and update the user information for that account in the store
     */
    getUserInfo(account) {
      // console.log('get user info',user);
      let that = this;
      that
        .$http({
          url: "api/user/userdetails/" + account,
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          let data = res.data;
          if (data.code == 200) {
            that.$store.commit("updateUser", data.data);
          }
        })
        .catch(() => {
          let data = {
            phone: "",
            user_name: account,
            organization: "莞香证券配资系统",
            avatar:
              "https://image.gxzbapi.com/face.png",
            account: account,
          };
          that.$store.commit("updateUser", data);
        });
    },

    /**
     * get channel information on given account
     * and update the channel information for that account in the store
     */
    getChannelInfo(account) {
      // console.log('get user info',user);
      let that = this;
      that
        .$http({
          url: "api/group/" + account + "?noUserData=1&type=1",
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          let data = res.data;
          if (data.code == 200) {
            that.$store.commit("updateChannel", data.data);
          }
        });
    },
    /**
     * show the notice
     */
    makeToast(message, account, titleText) {
      var content;
      switch (message.format) {
        case "0":
          content = this.processContent(message.content);
          break;
        case "1":
          content = "图片";
          break;
        case "8":
          content = "视频";
          break;

        default:
          break;
      }
      this.$vToastify.success({
        title: titleText ? titleText : "新信息",
        body: content,
        type: "success",
        canTimeout: true,
        duration: 5000,
        position: "top-right",
      });
    },
    sendMessage(format = 0, content = null) {
      switch (format) {
        case 0:
          content = this.inputMessage;
          this.sendMessageApi(content, format, this.selectedUser.id);
          break;
        case 1:
          this.sendMessageApi(
            JSON.stringify(content),
            format,
            this.selectedUser.id
          );
          break;
        case 3:
          this.sendMessageApi(
            JSON.stringify(content),
            format,
            this.selectedUser.id
          );
          break;
        case 8:
          this.sendMessageApi(
            JSON.stringify(content),
            format,
            this.selectedUser.id
          );
          break;
        default:
          break;
      }
      this.inputMessage = "";
    },
    /**
     * send the message
     */
    sendMessageApi(
      content,
      format,
      receiver,
      resend = false,
      resendData = null
    ) {
      let that = this;
      if (content === " " || content.length <= 1) {
        this.emptyMessageError = true;
        return;
      }

      let data = {
        content: content,
        action: that.channelButtonGroup ? "1" : "0",
        sender: that.$store.state.login_account.account,
        receiver: receiver,
        format: format,
        sending: true,
      };

      let tempId = Date.now();
      if (!resend) {
        data.id = tempId;
        data.read = true;
        data.timestamp = tempId;
        that.$store.commit("setMessage", {
          message: data,
          caller: that,
          sentMessage: true,
        });
      } else {
        data.oldId = resendData.id;
        data.sending = true;
        that.$store.commit("updateMessage", {
          message: data,
          caller: that,
          sentMessage: true,
        });
      }

      // setTimeout(() => {
      $(".chatWindowBody").animate(
        { scrollTop: $("#chatWindowBodyUl").prop("scrollHeight") },
        500
      );
      // }, 500);
      data.id = null;
      that
        .$http({
          url: "/api/message/send",
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: data,
        })
        .then((res) => {
          if (res.data.code == 200) {
            data.timestamp = res.data.timestamp;
            data.id = res.data.id;
            data.read = true;

            if (!resend) {
              // that.$store.commit("setMessage", {
              //   message: data,
              //   caller: that,
              //   sentMessage: true,
              // });
              data.oldId = tempId; // resendData.id;
              data.sending = false;
              that.$store.commit("updateMessage", {
                message: data,
                caller: that,
                sentMessage: true,
              });
            } else {
              data.oldId = resendData.id;
              data.sending = false;
              that.$store.commit("updateMessage", {
                message: data,
                caller: that,
                sentMessage: true,
              });
            }

            // $(".chatWindowBody").animate(
            //   { scrollTop: $(".chatWindowBodyUl").height() },
            //   "fast"
            // );
            // console.log($(".chatWindowBody").height());
            // $('.chatWindowBody').animate({scrollTop: $('#bottomChat').offset().top}, "slow");
          }
        })
        .catch((res) => {
          console.log("error sending message");
          if (!resend) {
            data.timestamp = new Date().getTime();
            data.id = "404" + new Date().getTime();
            data.read = true;
            data.sendfail = true;
            data.sending = false;
            (data.action = that.channelButtonGroup ? "1" : "0"),
              that.$store.commit("setMessage", {
                message: data,
                caller: that,
                sentMessage: true,
              });
          } else {
            data.sending = false;
            data.sendfail = true;
            that.$store.commit("updateMessage", {
              message: data,
              caller: that,
              sentMessage: true,
            });
          }
        })
        .finally(() => {
          // setTimeout(() => {
          //   $(".chatWindowBody").animate(
          //     { scrollTop: $("#chatWindowBodyUl").prop("scrollHeight") },
          //     500
          //   );
          // }, 500);
        });
    },
    resendFailMessage(message) {
      this.sendMessageApi(
        message.content,
        message.format,
        message.receiver,
        true,
        message
      );
    },
    showChannelMembers() {
      this.$refs.showChannelMembers.loadChannelMembers();
    },

    showMembersList() {
      this.$refs.showMembersList.showMembersList();
    },

    getHistoryMsg() {
      this.$refs.getHistoryMsg.loadHistory();
    },

    getHistoryChannel() {
      this.$refs.getHistoryChannel.loadHistoryChannel();
    },

    appendQuestion(msg) {
      this.inputMessage = msg;
    },
    processContent(content) {
      let that = this;
      content = content.replace(/face\[([^\s[\]]+?)\]/g, function (face) {
        //转义表情
        var alt = face.replace(/^face/g, "");
        var imgPath = that.emojiIcons.find((o) => alt === o.key);
        return (
          '<img alt="' +
          alt +
          '" title="' +
          alt +
          '" src="' +
          imgPath.text +
          '">'
        );
      });
      return content;
    },
    onEmoji(emoji) {
      this.showPopoverEmoji = false;
      this.inputMessage += "face" + emoji.key;
      this.onCloseEmojiPop();
    },
    onImageUpload(e) {
      this.uploadFile(e.target.files[0], 3);
    },
    onVideoUpload(e) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      this.uploadApiFile(formData, 8);
    },
    onFileUpload(e) {
      let formData = new FormData();
      formData.append("file", e.target.files[0]);
      this.uploadApiFile(formData, 3);
    },
    uploadFile(file, format) {
      let that = this;
      let formData = new FormData();
      formData.append("file", file);
      // Toast.loading();
      this.isShowLoader = true;
      that
        .$http({
          url: "/web/file",
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        })
        .then((res) => {
          this.isShowLoader = false;
          var data = res.data;
          if (data.code == 200) {
            that.sendMessage(1, data.data);
          } else {
            this.isShowLoader = false;
          }
        })
        .finally(() => {
          this.isShowLoader = false;
        });
    },
    uploadApiFile(formData, format) {
      let that = this;
      this.isShowLoader = true;
      that
        .$http({
          url: "/api/file/file",
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
            "Content-Type": "multipart/form-data",
          },
          data: formData,
        })
        .then((res) => {
          this.isShowLoader = false;
          var data = res.data;
          var content = {};
          if (data.code == 200) {
            switch (format) {
              case 8:
                content = {
                  duration: 2,
                  image: "73859a7c9b404f16b4c818b0d2983a9c.jpg",
                  mode: 1,
                  size: "5",
                  video: data.data.file,
                };
                that.sendMessage(8, content);
                break;
              case 3:
                content = {
                  file: data.data.file,
                  name: data.data.original,
                  size: "24280",
                };
                that.sendMessage(3, content);
                break;
              default:
                break;
            }
          } else {
            this.isShowLoader = false;
          }
        })
        .finally(() => {
          this.isShowLoader = false;
        });
    },
    loadOnlineUsers() {
      let that = this;
      that
        .$http({
          url: "api/user/online",
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          var data = res.data.data;
          that.$store.commit("updateUserList", data.agentList);
        });
    },
    onFile(content) {
      let url = this.$baseStoragePath + JSON.parse(content).file;
      window.open(url);
    },
    loadSystemMessages() {
      let that = this;
      that
        .$http({
          url: "api/message/getMessageHistory/" + 0 + "/system",
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          that.listLoading = false;
          let data = res.data;
          if (data.data.length > 0) {
            that.$store.commit("addMessageToSystem", data.data);
          }
          that.$store.commit("readSystemMessages");
        });
    },
    onSearchButton() {
      eventBus.$emit("onShowSearchPop");
    },
    onSearchButtonChannel() {
      eventBus.$emit("onShowSearchPopChannel");
    },
    onSelectedUser(data) {
      //search for the user in the recent list
      //if in the list load the chat
      //if not in the list add to the list and open the chat
      let account = this.$store.state.message_list.find(
        (o) => o.id === data.account
      );
      if (!account) {
        this.$store.commit("updateUserList", [data]);
        this.onSelectedUser(data);
        return;
      }
      account.timestamp = Date.now();
      this.$store.state.message_list = this.$store.state.message_list
        .slice()
        .sort((a, b) => b.timestamp - a.timestamp);
      this.onRecentUser(account);
    },
    onSelectedChannel(data) {
      //search for the user in the recent list
      //if in the list load the chat
      //if not in the list add to the list and open the chat
      let account = this.$store.state.channel_list.find(
        (o) => o.id === data.id
      );
      if (!account) {
        this.$store.commit("updateChannelList", [data]);
        this.onSelectedUser(data);
        return;
      }
      account.timestamp = Date.now();
      this.$store.state.channel_list = this.$store.state.channel_list
        .slice()
        .sort((a, b) => b.timestamp - a.timestamp);
      this.onRecentChannel(account);
    },
    // ============== show channel and agent list ======================
    showChannelList() {
      this.channelListPannel = true;
      this.agentListPannel = false;

      // this.channelButtonGroup = true;
      // this.agentButtonGroup = false;
    },

    showAgentList() {
      this.agentListPannel = true;
      this.channelListPannel = false;

      // this.agentButtonGroup = true;
      // this.channelButtonGroup = false;
    },
    playSound() {
      var audio = new Audio(noticeSound);
      audio.play();
    },
    //============================== load all channel list ================
    loadAllChannel() {
      let that = this;
      that
        .$http({
          url: "/api/group/list?type=1",
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          if (res.data.code == 200) {
            // that.allChannelList = res.data.dataList;
            that.$store.commit("updateChannelList", res.data.dataList);
          }
        });
    },
    //============================== click on one channel ==============
    onRecentChannel(item) {
      // this.selectedChannel.channelname = item.name;
      // this.selectedChannel.channelid = item.id;

      //show channel button group in the top
      this.channelButtonGroup = true;
      this.agentButtonGroup = false;

      this.selectedUser = item.user;
      this.selectedMessageList = item.messages;

      item.unread = 0;
      //set unread messages read
      this.$store.commit("readAllUnreadChannel", {
        account: this.selectedUser.account,
        context: this,
      });
      setTimeout(() => {
        $(".chatWindowBody").animate(
          { scrollTop: $("#chatWindowBodyUl").prop("scrollHeight") },
          500
        );
      }, 500);
    },
    /**
     * get information about who sent the message
     *
     * 1. get message sender information from api
     * 2. store in storage
     * 3. next tiime before getting from api check storage if not found get from api
     */
    getMessageSenderInformation(sender) {
      let that = this;

      var user = that.$store.state.member_cache.find((o) => o.id === sender);
      if (user) {
        return user.username;
      }

      that
        .$http({
          url: "api/user/getUserUpdated?account=" + sender,
          method: "get",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          if (res.data.code == 200) {
            var data = res.data.data[sender];
            that.$store.commit("addMemberCache", data);
            return data.username;
          }
          return sender;
        })
        .catch(() => {
          return sender;
        });
    },
    onPaste(pasteEvent, callback) {
      var items = pasteEvent.clipboardData.files;

      if (items.length > 0) {
        if (items[0].type === "image/png") {
          this.uploadFile(items[0], 3);
        } else if (items[0].type === "image/jpeg") {
          this.uploadFile(items[0], 3);
        } else {
          let formData = new FormData();
          formData.append("file", items[0]);
          this.uploadApiFile(formData, 3);
        }
      }
    },
  },
  mounted: function () {
    this.isLogin();
    this.$store.commit("clearOlderMessages");
    eventBus.$on("onSelectedUser", (data) => {
      this.onSelectedUser(data);
    });
    eventBus.$on("onSelectedChannel", (data) => {
      this.onSelectedChannel(data);
    });

    eventBus.$on("logout", () => {
      this.logout();
    });
    this.loadAllChannel();
  },
};
</script>

<style scoped>
/* .mainContainer {
  background: url(https://chatapp.hk9999z.com/resource/image/pattern.png) repeat,
    linear-gradient(#e1e4e7, #f3f4f5);
} */

.ownerName {
  padding: 10px 0px;
  height: 78px;
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}

.leftPanelStyle {
  height: 100%;
  background: #2c3e50;
}
.profileImage {
  width: 60px;
  padding: 3px;
  height: 60px;
  float: left;
  border: 2px solid #2ecc71;
}

.profileName {
  text-align: left;
  padding-top: 17px;
  margin: 0;
  color: #ffffff;
  font-size: 14px;
}

.profileNameList {
  text-align: left;
  padding-top: 17px;
  margin: 0;
  color: #ffffff;
  /* padding-left: 20px; */
}

.UserDetail {
  text-align: left;
  margin: 0;
  color: #424344;
  font-size: 13px;
}

.userInfoIcon {
  margin-top: 20px;
  color: #f8f9fa;
  width: 20px;
  height: 20px;
}

.copyButton {
  float: right;
  padding: 0px 7px;
  font-size: 11px;
}

.agentDetail {
  color: #2c3e50;
  font-size: 16px;
}

.container1 {
  height: auto;
  min-height: calc(100% - 188px);
  max-height: calc(100% - 188px);
  overflow-y: scroll;
  overflow-x: hidden;
  margin-bottom: 40px;
}

.container1::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.messageCount {
  padding-top: 10px;
  float: right;
}

.bottomBar {
  height: 53px;
  position: absolute;
  width: 100%;
  bottom: 0;
  background: #2c3e50;
  border-top: 1px solid #666d73;
}

.bottomContent {
  /* padding-right: 0px; */
  border-right: 1px solid #666d73;
}

.groupName {
  padding-top: 17px;
  margin: 0;
  padding: 11px 0px;
  color: #ffffff;
  text-align: center;
  margin-top: 6px;
  cursor: pointer;
}

/* ============= chat styles ============ */

.chatWindowBody {
  height: auto;
  min-height: calc(100% - 104px);
  max-height: calc(100% - 104px);
  overflow-y: scroll;
  overflow-x: hidden;
}

.chatWindowBody::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.chatWindowBody ul li {
  list-style: none;
}
.chatUsername {
  text-align: left;
  margin: 0;
  line-height: 50px;
  color: #ffffff;
  font-weight: bold;
}
.receive {
  float: left;
  margin: 15px 15px 5px 15px;
  width: calc(100% - 25px);
  display: inline-flex;
}

.receive .receiveFileStyle {
  text-align: left;
}

.receive .receiveFileStyle .receiveFileName {
  text-align: left;
}

.imageleft {
  width: 30px;
  border-radius: 50%;
  float: left;
  margin: 6px 8px 0 0;
  height: 30px;
}

.talktext {
  display: inline-block;
  position: relative;
  width: auto;
  height: auto;
  background-color: #435f7a;
  padding: 10px 15px;
  border-radius: 20px;
  color: #ffffff;
}

.talktext p {
  margin: 0px;
  max-width: 600px;
  text-align: justify;
}

.sent {
  margin: 15px 15px 5px 15px;
  width: calc(100% - 25px);
  display: inline-block;
  text-align: right;
}

.sent .textStyle {
  text-align: right;
}

.sent .fileName {
  text-align: right;
}

.imageright {
  width: 30px;
  border-radius: 50%;
  float: right;
  margin: 6px 0 0 8px;
  height: 30px;
}

.message_input {
  height: 53px;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99;
}

.emojiDesign {
  background-color: #fff;
  box-shadow: 0 0 20px rgb(0 0 0 / 20%);
  margin: 0;
  padding: 7px 14px;
  height: 150px;
  width: 234px;
}

.emojiListDesign {
  cursor: pointer;
  float: left;
  border: 1px solid #e8e8e8;
  overflow: hidden;
  list-style: none;
  padding: 5px;
}

.popoverEmoji .popover-body {
  padding: 0px;
}
</style>
<style>
.popover-body {
  padding: 0px;
}
</style>